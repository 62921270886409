// @flow
import { type JSSThemeDefaultSitemapType } from '../JSSThemeDefaultSitemap';

export const JSSThemeSitemap200: JSSThemeDefaultSitemapType = themeVars => {
  const {
    colors: {
      colorTypes: {},
    },
  } = themeVars;
  return {
    sitemapTitle: {
      color: '#575757',
    },
    firstLinkLevel: {
      color: '#575757',
      '&:hover': {
        color: ' rgba(57, 57, 57, 0.72)',
      },
    },
    secondLinkLevel: {
      color: '#575757',
      '&:hover': {
        color: ' rgba(57, 57, 57, 0.72)',
      },
    },
    thirdLinkLevel: {
      color: ' rgba(57, 57, 57, 0.72)',
      fontSize: 15,
      fontWeight: 'normal',
      listStyle: 'none',
      '&:hover': {
        color: '#575757',
      },
    },
  };
};
