// @flow
import { type JSSThemeDefaultCouponsType } from '../JSSThemeDefaultCoupons';

export const JSSThemeCoupons200: JSSThemeDefaultCouponsType = (themeVars) => {
  const {
    colors: {
      colorTypes: { black, neutral3, warning },
    },
    margins,
    fontSizes,
  } = themeVars;
  return {
    MobileWidgetCouponView: {
      sportHeader: {
        color: black,
      },
    },
    SportTabs: {
      tab: {
        neutral3,
        '&:hover': {
          color: neutral3,
        },
      },
    },
    WidgetSportMobile: {
      league: {
        color: black,
        '&>a': {
          color: black, // TODO remove since scss is deleted
        },
      },
    },
    EventPrimaryMarketOutcomesMobile: {
      teams: {
        color: neutral3,
      },
      teamItem: {
        color: neutral3,
      },
      date: {
        color: neutral3,
      },
    },
    WidgetSport: {
      sport: {
        padding: [0, margins.lg],
      },
    },
    Market: {
      root: {
        borderTop: 'none',
      },
    },
    Betbuilder: {
      toggler: {
        color: neutral3,
        background: warning,
        '&:hover': {
          background: warning,
        },
      },
      exampleContainer: {
        background: 'transparent',
        padding: margins.md,
        fontSize: fontSizes.md,
      },
    },
    PrematchEvent: {
      betbuilderMark: {
        color: neutral3,
      },
    },
  };
};
