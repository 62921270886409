// @flow
import { type JSSThemeDefaultAuthFormType } from '../JSSThemeDefaultAuthForm';

export const JSSThemeAuthForm200: JSSThemeDefaultAuthFormType = (themeVars) => {
  const {
    colors: {
      colorTypes: { text2, neutral1 },
    },
    fontSizes,
  } = themeVars;
  return {
    DepositButton: {
      link: {
        color: text2,
        '&:hover': {
          color: text2,
          opacity: 0.7,
        },
      },
      iconWrap: {},
    },
    LoginFormDesktop: {
      popUpWrapper: {
        background: neutral1,
        boxShadow: 'none',
        '&:before': {
          background: neutral1,
        },
      },
      forgotText: {
        fontSize: fontSizes.sm,
      },
    },
    PasswordResetWithEmail: {
      passwordResetDebounce: {
        color: '#575757',
        '@media (pointer: coarse)': {
          color: 'inherit',
        },
      },
    },
  };
};
