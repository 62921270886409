// @flow
import { type JSSThemeDefaultPromotionsType } from '../JSSThemeDefaultPromotions';

export const JSSThemePromotions200: JSSThemeDefaultPromotionsType = (
  themeVars
) => {
  const {
    fontSizes,
    margins,
    colors: {
      colorTypes: { white, text2, text1 },
    },
  } = themeVars;
  return {
    PromotionsComponent: {
      itemsByType: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      itemsByTypeTitle: {
        textAlign: 'left',
        fontWeight: 'normal',
        fontSize: fontSizes.xl,
        marginBottom: margins.md,
      },
      selectedLink: {
        color: `${text2} !important`,
        borderBottom: `3px solid ${text2} !important`,
        '&:hover': {
          opacity: `1 !important`,
        },
      },
      promoLink: {
        color: text1,
        '&:hover, &:active': {
          color: text1,
          opacity: 0.7,
          borderBottom: `3px solid ${text1}`,
        },
      },
    },

    SeasonalPromotion: {
      root: {
        padding: '20px 25px 40px',
      },
    },

    PromotionsWrapper: {
      promotionsWrapper: {
        padding: [0, margins.xm],
      },
      promotionsHeading: {
        fontSize: fontSizes.xxl,
        fontWeight: 'bold',
        padding: [margins.lg, 0, margins.xs, 0],
      },
    },
    PromotionUI: {
      buttonSection: {
        marginTop: 'auto',
        paddingTop: 10,
      },
      promoItemContainer: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        flexBasis: '100%',
        width: '100%',
        marginBottom: margins.xl - 10,
        '@media screen and (min-width: 1300px)': {
          flexBasis: `calc((100% - ${margins.xm}px) / 2)`,
          '&:nth-child(odd)': {
            marginRight: margins.xm,
          },
        },
      },
      itemBody: {
        backgroundPosition: '30% center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundColor: white,
        overflow: 'hidden',
        padding: margins.xm,
        display: 'flex',
      },
      bannerContentWrapper: {
        display: 'flex',
        width: '295px',
      },
      itemSecondTitle: {
        fontSize: fontSizes.xl,
        fontWeight: 'bold',
        marginTop: margins.md,
        lineHeight: 1.2,
      },
      itemDescription: {
        fontSize: fontSizes.md,
        paddingTop: 6,
      },
    },
  };
};
