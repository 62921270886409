// @flow
import { type JSSThemeDefaultBalanceType } from '../JSSThemeDefaultBalance';

export const JSSThemeBalance200: JSSThemeDefaultBalanceType = themeVars => {
  const {
    colors: {
      colorTypes: { neutral3, text1 },
    },
  } = themeVars;
  return {
    AccountPageMenu: {
      userMenuItem: {
        color: neutral3,
        '&:hover': {
          color: text1,
        },
      },
    },
  };
};
