// @flow
import { type JSSThemeDefaultEventSliderType } from '../JSSThemeDefaultEventSlider';

export const JSSThemeEventSlider200: JSSThemeDefaultEventSliderType = themeVars => {
  const {
    colors: {
      colorTypes: { white },
    },
  } = themeVars;
  return {
    EventSlide: {
      root: {
        background: white,
      },
    },
  };
};
