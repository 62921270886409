// @flow
import { JSSThemeVariables } from '../JSSThemeDefault';

export const JSSThemeSearch200 = (themeVars: typeof JSSThemeVariables) => {
  const {
    colors: {
      colorTypes: { neutral1, neutral3 },
    },
  } = themeVars;

  return {
    SearchBar: {
      resultContainer: {
        background: neutral1,
      },
    },
    SportsbookSearchResult: {
      title: {
        color: neutral3,
      },
      description: {
        color: neutral3,
        '&:hover': {
          color: '#000',
        },
      },
    },
  };
};
