// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme200,
  JSSThemeVariables200,
  getJSSThemePatterns200,
} from './JSSTheme200';
import { GeoblockPage200 } from './GeoblockPage200';
// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: () =>
      import('./FullDesktop200').then(module => module.FullDesktop200),
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: () =>
      import('./FullMobile200').then(module => module.FullMobile200),
  }),
  JSSTheme: JSSTheme200,
  JSSThemeVariables: JSSThemeVariables200,
  JSSThemePatterns: getJSSThemePatterns200,
  GeoblockPage: GeoblockPage200,
});
