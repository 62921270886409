// @flow
import { type JSSThemeDefaultDropdownMenuType } from '../JSSThemeDefaultDropdownMenu';

export const JSSThemeDropdownMenu200: JSSThemeDefaultDropdownMenuType = themeVars => {
  const {
    colors: {
      colorTypes: { neutral1, neutral2 },
    },
  } = themeVars;
  return {
    dropdownDesktop: {
      item: {
        background: neutral1,
        '&:hover,&.active': { color: neutral2, background: neutral1 },
      },
    },
  };
};


