// @flow
import { type JSSThemeDefaultBetslipType } from '../JSSThemeDefaultBetslip';

export const JSSThemeBetslip: JSSThemeDefaultBetslipType = themeVars => {
  const {
    colors: {
      colorCombinations: {
        neutral1Neutral2: betslipPanelPattern,
        text2Neutral1,
        text2Primary,
        whiteError,
      },
      colorTypes: { background1, text2, neutral1 },
    },
    fontSizes,
  } = themeVars;
  return {
    Betslip: {
      root: {
        background: background1,
      },
    },
    BetslipTotals: {
      container: {
        color: neutral1,
      },
      root: {
        borderTop: 'none',
      },
      summaryValue: {
        fontSize: fontSizes.xxl,
      },
      taxSummary: {
        color: neutral1,
      },
    },
    EachWayButton: {
      root: {
        color: text2,
      },
      label: {
        '&:after': {
          border: `3px solid ${text2}`,
        },
      },
    },
    SingleSelectionInfo: {
      liveLabel: text2Primary,
      eventDescription: betslipPanelPattern,
      marketDescription: betslipPanelPattern,
      priceDescription: betslipPanelPattern,
      remove: {
        ...betslipPanelPattern,
        fontSize: fontSizes.xs,
        opacity: 'unset',
      },
    },
    BetslipErrors: {
      list: {
        borderRadius: 0,
        ...text2Neutral1,
      },
    },
    NonSingleBody: {
      selectionInfo: betslipPanelPattern,
    },
    TextPair: {
      row: betslipPanelPattern,
    },
    SystemType: {
      amount: {
        color: betslipPanelPattern.color,
      },
    },
    BetslipInput: {
      label: {
        color: text2,
      },
    },
    Receipt: {
      root: {
        background: background1,
      },
      controls: {
        background: background1,
      },
      titleContainer: {
        fontSize: fontSizes.md,
        borderRadius: 0,
        ...text2Primary,
      },
    },
    SingleSelectionStaticInfo: {
      root: {
        color: neutral1,
      },
    },
    ReceiptSelectionAmounts: {
      root: betslipPanelPattern,
      label: betslipPanelPattern,
      value: betslipPanelPattern,
    },
    ReceiptNonSingleSelection: {
      systemTitle: {
        color: betslipPanelPattern.color,
      },
    },
    SelectionState: {
      suspended: text2Primary,
      closed: whiteError,
    },
  };
};
