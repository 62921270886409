// @flow
import * as React from 'react';
import { useJSSStyles } from '../../jss-hooks';
import { type JSSThemeType } from '../../origins/JSSThemeDefault';
const path = (theme: JSSThemeType) => theme.components.Geoblock;

export const GeoblockPage200 = () => {
  const classes = useJSSStyles(path);
  return (
    <div className={classes.rootGeoblock}>
      <div className={classes.logoGeoblock}>
        <img
          src="//cdn.processingservices.biz/casino/upload_546550812e26f1274c7ac4f9a1ff713e.svg"
          alt="logo"
          className={classes.imgGeoblock}
        />
      </div>
      <div className={classes.textGeoblock}>{_t('site-is-geoblocked')}</div>
    </div>
  );
};
