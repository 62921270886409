// @flow
import { type JSSThemeDefaultHeaderMenuType } from '../JSSThemeDefaultHeaderMenu';

export const JSSThemeHeaderMenu200: JSSThemeDefaultHeaderMenuType = themeVars => {
  const {
    margins,
    fontSizes,
    colors: {
      colorTypes: { text1, background1 },
      colorCombinations: { text2Primary },
    },
  } = themeVars;
  const menuItemAfterElement = {
    content: '""',
    position: 'absolute',
    textAlign: 'center',
    bottom: '-27px',
    left: '6%',
    width: '90%',
    borderBottom: `3px solid ${text1}`,
  };
  return {
    HeaderMenuDesktop: {
      menuWrapper: text2Primary,
      menuItem: {
        padding: [0, margins.xm],
        '&:hover, &:focus': {
          color: text1,
          '&:after': menuItemAfterElement,
        },
        '@media screen and (max-width: 1600px)': {
          padding: '0px 7px',
        },
        '@media screen and (max-width: 1460px)': {
          fontSize: 12,
        },
        '@media screen and (max-width: 1146px)': {
          padding: '0px 5px',
        },
        '@media screen and (max-width: 1056px)': {
          fontSize: 11,
        },
      },
      menuItemActive: {
        color: text1,
        '&:after': menuItemAfterElement,
      },
      menuItemWrapper: {
        borderRight: `1px solid ${background1}`,
      },
    },
    HeaderMenuMobile: {
      menuItem: {
        fontSize: fontSizes.xl,
      },
    },
  };
};
