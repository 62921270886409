// @flow
import { JSSThemeVariables } from '../JSSThemeDefault';

export const JSSThemeLanguageSwitcher200 = (
  themeVars: typeof JSSThemeVariables
) => {
  const {
    colors: {
      colorTypes: { neutral1, neutral2 },
    },
  } = themeVars;
  return {
    LanguageSelectComponent: {
      languageSelect: {
        '&:before': {
          background: neutral1,
        },
      },
      languageItem: {
        background: neutral1,
        '&:hover': { background: neutral1, color: neutral2 },
      },
    },
  };
};
