// @flow
import { type JSSThemeDefaultPopularLeaguesType } from '../JSSThemeDefaultPopularLeagues';

export const JSSThemePopularLeagues200: JSSThemeDefaultPopularLeaguesType = themeVars => {
  const {
    colors: {
      colorTypes: { text1, text2, white },
    },
    fontSizes,
  } = themeVars;
  return {
    PopularLeagues: {
      root: {
        background: text1,
        '@media (pointer: coarse)': {
          background: text2,
        },
      },
      link: {
        '&:active, &:focus': {
          color: white,
        },
      },
      header: {
        height: 'auto',
        fontSize: fontSizes.xxl,
      },
    },
  };
};
