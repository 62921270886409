// @flow
import { type JSSThemeDefaultBetgeniusType } from '../JSSThemeDefaultBetgenius';

export const JSSThemeBetgenius200: JSSThemeDefaultBetgeniusType = (
  themeVars
) => {
  return {
    liveBetbuilderWrapper: {
      margin: '10px 0',

      '@media (max-width: 1024px)': {
        margin: 5,
      },
    },
  };
};
