// @flow
import { type JSSThemeDefaultLiveType } from '../JSSThemeDefaultLive';

export const JSSThemeLive200: JSSThemeDefaultLiveType = themeVars => {
  const {
    colors: {
      colorTypes: { white, neutral3 },
    },
  } = themeVars;
  return {
    LiveMenuEvent: {
      LiveEventRanked: {
        background: white,
      },
      LiveIcon: {
        fill: neutral3,
      },
    },
    LiveMenuEventHeading: {
      LiveIcon: {
        fill: neutral3,
      },
    },
    LiveMenuRankedEvent: {
      LiveIcon: {
        fill: neutral3,
      },
    },
    LiveStreamingMessage: {
      loginButton: {
        color: neutral3,
      },
    },
  };
};
