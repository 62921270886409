// @flow
import { type JSSThemeDefaultBannerItemsType } from '../JSSThemeDefaultBannerItems';

export const JSSThemeBannerItems200: JSSThemeDefaultBannerItemsType = themeVars => {
  const {
    colors: {
      colorTypes: { primary, text1, text2, white, border2, background2 },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    BannerContent: {
      bannerContentRoot: {
        color: text2,
      },
      bannerContentFill: {},
      bannerContentSportsbookCarousel: {
        width: '100%',
        padding: [0, margins.md, margins.md],
        position: 'relative',
        justifyContent: 'flex-end',
      },
      bannerContentCasinoCarousel: {
        justifyContent: 'flex-end',
        padding: 0,
        position: 'relative',
      },
      bannerContentSportsbookSide: {
        padding: '0',
      },
      bannerContentCasinoSide: {
        padding: '0',
      },
      bannerContentPromo: {
        padding: '0',
        justifyContent: 'space-between',
      },
      headerSportsbookCarousel: {
        fontSize: 35,
        lineHeight: 0.9,
        letterSpacing: 'normal',
        fontWeight: '900',
        color: background2,
        marginRight: margins.md,
        display: 'inline-block',
      },
      descriptionSportsbookCarousel: {
        fontSize: 20,
        fontWeight: '900',
        color: background2,
        padding: '0',
        lineHeight: 1.1,
        display: 'inline-block',
      },
      headerCasinoCarousel: {
        fontSize: 100,
        lineHeight: '90px',
        fontWeight: '900',
        color: white,
        marginBottom: margins.md,
      },
      headerSportsbookSide: {
        fontSize: 46,
        letterSpacing: '-2px',
        lineHeight: '0.9',
        textShadow: 'none',
        fontWeight: '900',
        color: white,
      },
      headerCasinoSide: {
        fontSize: 46,
        letterSpacing: '-2px',
        lineHeight: '0.9',
        textShadow: 'none',
        fontWeight: '900',
        color: text2,
      },
      headerPromo: {
        fontSize: 68,
        textShadow: 'none',
        fontWeight: '900',
        color: text2,
      },
      headerMobile: {
        fontSize: 30,
      },
      descriptionCasinoCarousel: {
        fontSize: 40,
        lineHeight: '40px',
        fontWeight: '900',
        color: white,
        padding: '0',
      },
      descriptionSportsbookSide: {
        fontSize: 22,
        textShadow: 'none',
        fontWeight: '800',
        color: white,
        padding: '0',
      },
      descriptionCasinoSide: {
        fontSize: 22,
        textShadow: 'none',
        fontWeight: '800',
        color: text2,
        padding: '0',
      },
      descriptionPromo: {
        fontSize: 28,
        lineHeight: '28px',
        fontWeight: '800',
        color: text2,
        padding: '0',
      },
      descriptionMobile: {
        fontSize: 18,
      },
      textWrapperSportsbookCarousel: {
        padding: '10px 0 !important',
        fontStyle: 'normal',
      },
      textWrapperSportsbookSide: {
        padding: margins.md,
      },
      textWrapperCasinoSide: {
        padding: 0,
      },
      textWrapperPromo: {
        padding: [margins.xs, margins.xm, margins.xm],
      },
      buttonsWrapperRoot: {
        margin: 0,
      },
      buttonsWrapperCasinoCarousel: {
        margin: [margins.xm, 0, 0, 0],
      },
      buttonsWrapperPromo: {
        margin: [margins.md, 0, 0, 0],
      },
    },
    BannerItemSportsbookCarouselMobile: {
      imgContainer: {
        minHeight: 100,
        alignItems: 'flex-start',
      },
      header: {
        color: text2,
        textShadow: `none`,
      },
      description: {
        textAlign: 'left',
        color: text2,
        textShadow: `none`,
      },
    },
    BannerItemVirtualsCarousel: {
      contentWrapper: {
        padding: [0, 0, 0, margins.md],
      },
    },
    BannerItemSportsbookCarousel: {
      bannerItemSportsbookCarouselRoot: {
        marginRight: 0,
      },
      heightFix: {
        height: 282,
      },
      imgContainer: {
        height: '100%',
        backgroundColor: white,
        backgroundSize: 'auto',
        backgroundPosition: 'center right',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
      },
      contentWrapper: {
        width: '100%',
        display: 'block',
        height: 'auto',
        padding: 0,
        backgroundColor: primary,
      },
      contentWrapperMobile: {
        width: '100%',
        maxWidth: 380,
      },
    },
    CarouselSlidePrematch: {
      carouselSlidePrematchRoot: {
        background: white,
      },
      descriptionWrapper: {
        display: 'flex',
        fontSize: fontSizes.md,
      },
      descriptionTimeWrapper: {
        color: text1,
      },
      descriptionTeams: {
        color: text2,
      },
      linkEvent: {
        color: border2,
      },
    },
    SideBanner: {
      containerWithContent: {
        height: '400px',
        padding: margins.md,
      },
    },
    BannerItemCasinoCarousel: {
      heightFix: {
        height: 300,
      },
      mobile: {
        '&:after': {
          background: `linear-gradient(to right, rgba(0, 0, 0, 0.7) 0%, rgba(10, 31, 50, 0) 20%, rgba(10, 31, 50, 0) 100%)`,
        },
      },
      content: {
        justifyContent: 'flex-end',
      },
      contentWrapper: {
        width: '460px',
        height: ' 100%',
        padding: ['30px', margins.lg],
        '&:after': {
          content: `''`,
          position: 'absolute',
          width: '40%',
          height: '100%',
          zIndex: ' 0',
          top: 0,
          left: 0,
          background: `linear-gradient(to right, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 100%)`,
        },
      },
    },
  };
};
